var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-form-page" },
    [
      _c("div", { staticClass: "header-line" }, [
        _c("div", { staticClass: "header-left" }, [
          _c("div", { staticClass: "back-div", on: { click: _vm.routeBack } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/go_back_icon.png"),
                alt: "",
              },
            }),
            _c("span", [_vm._v("返回")]),
          ]),
          _c("div", { staticClass: "border-line" }),
          _c("div", { staticClass: "name-div" }, [
            _vm._v("\n        " + _vm._s(_vm.pageTitle) + "\n      "),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "header-right" },
          [
            _c(
              "div",
              { staticClass: "look-json", on: { click: _vm.jsonViewHander } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/dynamicForm/look_json.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "label-line" }, [
                  _vm._v("查看json代码"),
                ]),
              ]
            ),
            _c(
              "r-button",
              {
                attrs: { plain: "", loading: _vm.submitloading },
                on: { click: _vm.saveHandler },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "main-container" }, [
        _c(
          "div",
          { staticClass: "component-part" },
          _vm._l(_vm.componentsList, function (item) {
            return _c("div", { key: item.key, staticClass: "single-catelog" }, [
              _c(
                "div",
                {
                  staticClass: "catelog-title",
                  on: {
                    click: function ($event) {
                      item.active = !item.active
                    },
                  },
                },
                [
                  _c("div", { staticClass: "label-part" }, [
                    _c("img", { attrs: { src: item.icon, alt: "" } }),
                    _c("span", { staticClass: "label-span" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.label) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "icon-part" }, [
                    !item.active
                      ? _c("i", { staticClass: "el-icon-caret-right" })
                      : _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  style: {
                    height: item.active ? "auto" : "0",
                    overflow: "hidden",
                  },
                },
                [
                  _c(
                    "VueDraggable",
                    {
                      staticClass: "container component-list",
                      attrs: {
                        animation: "150",
                        ghostClass: "left-ghost",
                        sort: false,
                        group: {
                          name: "components",
                          pull: "clone",
                          put: false,
                        },
                        filter: ".ignore",
                        clone: _vm.cloneHandler,
                      },
                      model: {
                        value: item.list,
                        callback: function ($$v) {
                          _vm.$set(item, "list", $$v)
                        },
                        expression: "item.list",
                      },
                    },
                    _vm._l(item.list, function (single) {
                      return _c(
                        "div",
                        {
                          key: single.key,
                          staticClass: "single-component",
                          attrs: { "data-type": single.key },
                        },
                        [
                          _c("img", {
                            staticClass: "component-image",
                            attrs: { src: single.icon, alt: "" },
                          }),
                          _c("div", { staticClass: "component-label" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(single.label) +
                                "\n              "
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "graph-part",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.setItemActive.apply(null, arguments)
              },
            },
          },
          [
            _vm.formItemList.length === 0
              ? _c("div", { staticClass: "no-card" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/customer_add_icon.png"),
                      alt: "",
                    },
                  }),
                  _vm._v("\n        选择组件长按鼠标左键拖动到此区域\n      "),
                ])
              : _vm._e(),
            _c(
              "VueDraggable",
              {
                staticClass: "container",
                attrs: {
                  animation: "150",
                  ghostClass: _vm.dynamicGhost,
                  group: "components",
                  filter: ".ignore",
                },
                on: { add: _vm.addHandler },
                model: {
                  value: _vm.formItemList,
                  callback: function ($$v) {
                    _vm.formItemList = $$v
                  },
                  expression: "formItemList",
                },
              },
              _vm._l(_vm.formItemList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    class: [
                      `single-item-${item.key}`,
                      `single-item`,
                      item.active && "single-item-active",
                    ],
                    style: {
                      width: _vm.cardWidthReander(item),
                    },
                    attrs: { id: item.id },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.setItemActive(item)
                      },
                    },
                  },
                  [
                    _c("SingleFormItem", {
                      attrs: { dataset: item, readOnly: true },
                    }),
                    item.active
                      ? _c(
                          "div",
                          {
                            staticClass: "delete-container",
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(item)
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/dynamicForm/delete_icon.png"),
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "props-part" },
          [
            _c("ComponentProps", {
              ref: "propsRef",
              attrs: { dataset: _vm.currentSelect },
              on: { update: _vm.updateHandler },
            }),
          ],
          1
        ),
      ]),
      _c("JSONViewDialog", {
        attrs: { dataset: _vm.jsonView },
        on: { close: _vm.jsonViewClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }