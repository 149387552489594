import { cloneDeep } from 'lodash';

export const componentsList = [
  {
    key: 'formComponent',
    label: '表单组件',
    active: true,
    icon: require('../../../../assets/images/dynamicForm/component_icon_1.png'),
    list: [
      {
        key: 'input',
        label: '单行输入',
        icon: require('../../../../assets/images/dynamicForm/component_icon_input.png'),
      },
      {
        key: 'textarea',
        label: '多行输入',
        icon: require('../../../../assets/images/dynamicForm/component_icon_textarea.png'),
      },
      {
        key: 'selector',
        label: '下拉框',
        icon: require('../../../../assets/images/dynamicForm/component_icon_selector.png'),
      },
      // {
      //   key: 'selector',
      //   label: '下拉选择器',
      //   icon: require('../../../../assets/images/dynamicForm/component_icon_selector.png'),
      // },
    ],
  },
  {
    key: 'businessComponent',
    label: '业务组件',
    active: true,
    icon: require('../../../../assets/images/dynamicForm/component_icon_1.png'),
    list: [
      {
        key: 'companySelector',
        label: '项目下拉框',
        icon: require('../../../../assets/images/dynamicForm/component_icon_selector.png'),
      },
    ],
  },
];
/**
 * 标题：label
 * 编码：code
 * 占位内容：placeHolder
 * 默认内容：default
 * 最大字数：maxLength
 * 布局：layout
 *    - half / all
 * 必填：require
 *    - true / false
 * 占位：occupy
 *  {
 *    value: true | false,
 *    clearable: true | false,
 *    placeHolder: '请选择'
 *  }
 */
const configMaps = {
  input: [
    'label',
    'code',
    'placeHolder',
    'default',
    'maxLength',
    'layout',
    'require',
  ],
  textarea: [
    'label',
    'code',
    'placeHolder',
    'default',
    'maxLength',
    'layout',
    'require',
  ],
  selector: [
    'label',
    'code',
    'placeHolder2',
    'options',
    'default',
    'layout',
    'clearable',
    'multiple',
    'filterable',
    'require',
  ],
  companySelector: [
    'companyLabel',
    'code',
    'placeHolder2',
    'companyOptions',
    'default',
    'layout',
    'clearable',
    'multiple',
    'filterable',
    'require',
  ],
};
const singleConfigMaps = {
  label: {
    key: 'label',
    label: '标题',
    value: '未命名',
    placeHolder: '',
    require: true,
  },
  companyLabel: {
    key: 'label',
    label: '标题',
    value: '项目',
    placeHolder: '',
    require: true,
  },
  code: {
    key: 'code',
    label: '编码',
    value: null,
    placeHolder: '请输入',
    require: true,
  },
  placeHolder: {
    key: 'placeHolder',
    label: '占位内容',
    value: '请输入',
    placeHolder: '请输入',
    require: false,
  },
  placeHolder2: {
    key: 'placeHolder',
    label: '占位内容',
    value: '请选择',
    placeHolder: '请输入',
    require: false,
  },
  default: {
    key: 'default',
    label: '默认值',
    value: null,
    placeHolder: '请输入',
    require: false,
  },
  maxLength: {
    key: 'maxLength',
    label: '最大字数',
    value: 20,
    require: false,
  },
  layout: {
    key: 'layout',
    label: '布局',
    value: 'half',
    require: false,
  },
  require: {
    key: 'require',
    label: '必填',
    value: false,
    require: false,
  },
  occupy: {
    key: 'occupy',
    label: '占位',
    value: {
      value: true,
      clearable: true,
      placeHolder: '请选择',
    },
    require: false,
  },
  multiple: {
    key: 'multiple',
    label: '是否多选',
    value: false,
    require: false,
  },
  filterable: {
    key: 'filterable',
    label: '是否可搜索',
    value: true,
    require: false,
  },
  clearable: {
    key: 'clearable',
    label: '显示清除按钮',
    value: true,
    require: false,
  },
  options: {
    key: 'options',
    label: '选项',
    require: false,
    config: {
      type: 'static', // static | inner | classify
      optionList: [
        {
          value: null,
          label: null,
          selected: true,
        },
        {
          value: null,
          label: null,
          selected: false,
        },
      ],
      serviceUrl: null,
      labelKey: null,
      valueKey: null,
      classifyCode: null,
    },
  },
  companyOptions: {
    key: 'companyOptions',
    label: '选项',
    require: false,
    config: {
      type: 'inner',
      optionList: [],
      serviceUrl: '/saascloud/saas/base/tenant/project/list',
      labelKey: 'projectName',
      valueKey: 'projectId',
    },
  },
};
export const getPropsByType = (type) => {
  const configList = configMaps[type];
  return configList.map((item) => cloneDeep(singleConfigMaps[item]));
};
