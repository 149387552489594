<template>
  <div class="dynamic-form-page">
    <div class="header-line">
      <div class="header-left">
        <div class="back-div" @click="routeBack">
          <img src="@/assets/images/go_back_icon.png" alt="" />
          <span>返回</span>
        </div>
        <div class="border-line"></div>
        <div class="name-div">
          {{ pageTitle }}
        </div>
      </div>
      <div class="header-right">
        <div class="look-json" @click="jsonViewHander">
          <img src="@/assets/images/dynamicForm/look_json.png" alt="" />
          <div class="label-line">查看json代码</div>
        </div>
        <r-button plain @click="saveHandler" :loading="submitloading"
          >保存</r-button
        >
      </div>
    </div>
    <div class="main-container">
      <div class="component-part">
        <div
          class="single-catelog"
          v-for="item in componentsList"
          :key="item.key"
        >
          <div class="catelog-title" @click="item.active = !item.active">
            <div class="label-part">
              <img :src="item.icon" alt="" />
              <span class="label-span">
                {{ item.label }}
              </span>
            </div>
            <div class="icon-part">
              <i class="el-icon-caret-right" v-if="!item.active"></i>
              <i class="el-icon-caret-bottom" v-else></i>
            </div>
          </div>
          <div
            :style="{
              height: item.active ? 'auto' : '0',
              overflow: 'hidden',
            }"
          >
            <VueDraggable
              v-model="item.list"
              animation="150"
              ghostClass="left-ghost"
              class="container component-list"
              :sort="false"
              :group="{ name: 'components', pull: 'clone', put: false }"
              filter=".ignore"
              :clone="cloneHandler"
            >
              <div
                class="single-component"
                :data-type="single.key"
                v-for="single in item.list"
                :key="single.key"
              >
                <img class="component-image" :src="single.icon" alt="" />
                <div class="component-label">
                  {{ single.label }}
                </div>
              </div>
            </VueDraggable>
          </div>
        </div>
      </div>
      <div class="graph-part" @click.stop="setItemActive" v-loading="loading">
        <div class="no-card" v-if="formItemList.length === 0">
          <img src="@/assets/images/customer_add_icon.png" alt="" />
          选择组件长按鼠标左键拖动到此区域
        </div>
        <VueDraggable
          v-model="formItemList"
          animation="150"
          :ghostClass="dynamicGhost"
          class="container"
          group="components"
          filter=".ignore"
          @add="addHandler"
        >
          <div
            :class="[
              `single-item-${item.key}`,
              `single-item`,
              item.active && 'single-item-active',
            ]"
            :id="item.id"
            v-for="item in formItemList"
            :key="item.id"
            :style="{
              width: cardWidthReander(item),
            }"
            @click.stop="setItemActive(item)"
          >
            <SingleFormItem :dataset="item" :readOnly="true" />
            <div
              class="delete-container"
              v-if="item.active"
              @click="deleteItem(item)"
            >
              <img src="@/assets/images/dynamicForm/delete_icon.png" alt="" />
            </div>
          </div>
        </VueDraggable>
      </div>
      <div class="props-part">
        <ComponentProps
          ref="propsRef"
          :dataset="currentSelect"
          @update="updateHandler"
        />
      </div>
    </div>

    <!-- 查看json代码 -->
    <JSONViewDialog :dataset="jsonView" @close="jsonViewClose" />
  </div>
</template>

<script>
import { componentsList, getPropsByType } from "./datas.js";
import ComponentProps from "./components/componentProps.vue";
import { GenNonDuplicateID } from "@/utils/utils.js";
import { cloneDeep } from "lodash";
import { VueDraggable } from "vue-draggable-plus";
import SingleFormItem from "./components/singleFormItem.vue";
import JSONViewDialog from "./components/JSONViewDialog.vue";
import {
  findProductDetail,
  updateProductExtendInfo,
} from "@/api/ruge/vlink/product/product";

export default {
  name: "dynamic-form-page",
  components: {
    ComponentProps,
    VueDraggable,
    SingleFormItem,
    JSONViewDialog,
  },
  data() {
    return {
      componentsList,
      loading: false,
      submitloading: false,
      pageTitle: "扩展信息配置",
      currentSelect: {
        propsList: [],
        productInfo: this.getProductInfo(),
        id: null,
        type: null,
      },
      formItemList: [],
      dynamicGhost: "ghost-half",
      resource: {},
      jsonView: {
        show: false,
        json: null,
      },
    };
  },
  created() {
    this.initDatas();
    console.log("xxx", this.$router);
  },
  methods: {
    addHandler(card) {
      this.setItemActive(card.clonedData);
    },
    jsonViewHander() {
      this.jsonView.show = true;
      this.jsonView.json = JSON.stringify(this.formItemList, null, "\t");
    },
    jsonViewClose() {
      this.jsonView.show = false;
      this.jsonView.json = null;
    },
    simpleBuild(list) {
      return list.map((item) => {
        return {
          label: item.propsList.filter((single) => single.key === "label")[0]
            .value,
          value: item.propsList.filter((single) => single.key === "default")[0]
            .value,
          code: item.propsList.filter((single) => single.key === "code")[0]
            .value,
          require: item.propsList.filter(
            (single) => single.key === "require"
          )[0].value,
        };
      });
    },
    saveHandler() {
      if (!this.checkFormValid()) return;
      const { productId } = this.resource;
      const extendInfoTemplate = JSON.stringify({
        json: this.formItemList,
        simple: this.simpleBuild(this.formItemList),
      });
      const params = {
        productId,
        extendInfoTemplate,
      };
      this.submitloading = true;
      updateProductExtendInfo(params)
        .then(() => {
          this.$message.success("保存成功");
          setTimeout(() => {
            this.routeBack();
          }, 1000);
        })
        .finally(() => {
          this.submitloading = false;
        });
    },
    initDatas() {
      const { productKey } = this.$route.query;
      if (!productKey) return;
      this.loading = true;
      findProductDetail({ productKey })
        .then((response) => {
          this.currentSelect.productInfo = this.getProductInfo(response);
          this.resource = response;
          this.pageTitle += ` - ${response.productName}`;
          try {
            if (response.extendInfoTemplate) {
              this.formItemList = JSON.parse(
                response.extendInfoTemplate
              ).json.map((item) => {
                item.active = false;
                return item;
              });
            }
          } catch (error) {}
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkFormValid() {
      const repeatCodeMap = {};
      for (let item of this.formItemList) {
        for (let single of item.propsList) {
          const { require, value, key } = single;
          // 检查必填项code
          if (require && !value) {
            this.currentSelect.propsList = item.propsList;
            this.setItemActive(item);
            this.$nextTick(() => {
              this.setRepeatNodeError([item.id]);
              this.$refs.propsRef.checkForm();
            });
            return false;
          }
          // 检查default的长度是否超过maxLength
          if (key === "default") {
            const defaultLength = value ? value.length : 0;
            const maxLengthItem = item.propsList.filter(
              (item) => item.key === "maxLength"
            )[0];
            if (maxLengthItem) {
              const maxLength = maxLengthItem.value;
              if (defaultLength > maxLength) {
                this.setItemActive(item);
                this.$nextTick(() => {
                  this.setRepeatNodeError([item.id]);
                  this.$refs.propsRef.checkForm();
                });
                return false;
              }
            }
          }
          // 检查重复code
          if (key === "code") {
            if (!repeatCodeMap[value]) {
              repeatCodeMap[value] = item.id;
            } else {
              this.setRepeatNodeError([repeatCodeMap[value], item.id]);
              this.$message.warning("编码重复，请修改后再保存！");
              return false;
            }
          }
        }
      }
      return true;
    },
    setRepeatNodeError(list) {
      try {
        list.forEach((id) => {
          $(`#${id}`).addClass("error-node");
        });
        setTimeout(() => {
          list.forEach((id) => {
            $(`#${id}`).removeClass("error-node");
          });
        }, 3000);
      } catch (error) {}
    },
    cardWidthReander(item) {
      let defaultValue = "half";
      const { propsList } = item || {};
      if (propsList && propsList.length) {
        propsList.forEach((item) => {
          if (item.key === "layout") {
            defaultValue = item.value;
          }
        });
      }
      return defaultValue === "all" ? "100%" : `calc(50% - 15px)`;
    },
    updateHandler({ value, key }) {
      this.currentSelect.propsList.forEach((item) => {
        if (item.key === key) {
          item.value = value;
        }
      });
    },
    async deleteItem({ id }) {
      await this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      );
      for (let index = 0; index < this.formItemList.length; index++) {
        if (this.formItemList[index].id === id) {
          this.formItemList.splice(index, 1);
          this.currentSelect.propsList = [];
          break;
        }
      }
    },
    setItemActive({ id, key }) {
      this.currentSelect.propsList = [];
      this.currentSelect.id = null;
      this.currentSelect.type = key;
      this.formItemList.forEach((item) => {
        if (item.id === id) {
          item.active = true;
          this.currentSelect.propsList = item.propsList;
          this.currentSelect.id = id;
        } else {
          item.active = false;
        }
      });
      this.$forceUpdate();
    },
    cloneHandler(element) {
      const cloneObj = cloneDeep(element);
      delete cloneObj.icon;
      cloneObj.id = GenNonDuplicateID();
      cloneObj.active = false;
      cloneObj.propsList = getPropsByType(element.key);
      console.log("cloneObj", cloneObj);
      return cloneObj;
    },
    routeBack() {
      this.$router.go(-1);
    },
    getProductInfo(productInfo) {
      const { productName, nodeType, dataType, authType, protocol, isShared } =
        productInfo || {};
      const nodeTypeMap = {
        DIRECTDEVICE: "直连设备",
        GATEWAY: "网关设备",
        SUBDEVICE: "网关子设备",
      };
      const dataTypeMap = {
        JSON: this.$t("vlink.commons.json"),
        BINARY: this.$t("vlink.commons.binary"),
        // int: "int(整数)",
        // decimal: "decimal(小数)",
        // string: "string(字符串)",
        // dateTime: "dateTime(日期时间)",
        // enum: "enum(枚举)",
        // jsonObject: "jsonObject(JSON结构体)",
        // stringList: "stringList(字符串数组)",
        // img: "img(图片)",
      };
      const authTypeMap = {
        SECRETKEY: "密钥",
        X509: "x.509证书",
      };
      const protocolMap = {
        custom: this.$t("vlink.commons.custom"),
        modbus: this.$t("vlink.commons.modbus"),
        bacnet: this.$t("vlink.commons.bacnet"),
        opcua: this.$t("vlink.commons.opcua"),
      };
      const isShareMap = {
        Y: "是",
        N: "否",
      };
      return [
        {
          label: "产品名称",
          value: productName || "-",
          infoKey: "productName",
        },
        {
          label: "节点类型",
          value: nodeTypeMap[nodeType] || "-",
          infoKey: "nodeType",
        },
        {
          label: "数据格式",
          value: dataTypeMap[dataType] || "-",
          infoKey: "dataType",
        },
        {
          label: "认证方式",
          value: authTypeMap[authType] || "-",
          infoKey: "authType",
        },
        {
          label: "协议类型",
          value: protocolMap[protocol] || "-",
          infoKey: "protocol",
        },
        {
          label: "是否共享",
          value: isShareMap[isShared] || "-",
          infoKey: "isShared",
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.dynamic-form-page {
  background: #f7f8fa;
  .header-line {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e3e8ee;
    background: #ffffff;
    padding: 0 40px;
    .header-left {
      display: flex;
      align-items: cneter;
      .back-div {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        color: #2a61ff;
        img {
          margin-right: 4px;
          position: relative;
          top: -1px;
        }
        span {
          font-weight: 600;
        }
      }
      .border-line {
        width: 1px;
        height: 17px;
        background: #cbdbe9;
        margin: 0 10px;
      }
      .name-div {
        font-weight: 600;
        font-size: 18px;
        color: #252d3d;
      }
    }
    .header-right {
      display: flex;
      align-items: center;
      .look-json {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin-right: 32px;
        .label-line {
          font-weight: 500;
          font-size: 12px;
          color: #252d3d;
          margin-top: 6px;
        }
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .main-container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 70px);
    .component-part {
      width: 280px;
      height: 100%;
      border-right: 1px solid #e3e8ee;
      background: #ffffff;
      padding: 0 20px;
      .single-catelog {
        margin-top: 20px;
        .catelog-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e3e8ee;
          padding-bottom: 12px;
          cursor: pointer;
          .label-part {
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              position: relative;
              top: -1px;
            }
            .label-span {
              font-weight: 600;
              font-size: 16px;
              color: #252d3d;
            }
          }
          .icon-part {
            color: #a0aac0;
          }
        }
        .component-list {
          overflow: hidden;
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 12px;
          column-gap: 20px;
          margin-top: 12px;
          .single-component {
            width: 110px;
            height: 100px;
            background: #f7f8fa;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: default;
            &:hover {
              background: #f2f3f5;
            }
            &:active {
              background: #eeeff0;
            }
            .component-label {
              font-weight: 400;
              font-size: 12px;
              color: #252d3d;
              margin-top: 5px;
            }
          }
        }
      }
    }
    .graph-part {
      position: relative;
      margin: 20px 0;
      width: calc(100vw - 560px);
      overflow-y: auto;
      padding: 0px 40px;
      .no-card {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 14px;
        color: #b5bece;
        img {
          margin-bottom: 20px;
        }
      }
      .container {
        height: 100%;
        width: 100%;
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        align-content: flex-start;
        .single-item {
          border: 2px solid #00000000;
          position: relative;
          .delete-container {
            width: 24px;
            height: 20px;
            position: absolute;
            top: 0;
            right: 0;
            background: #2a61ff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          &:hover {
            border: 2px solid #2a61ff50;
          }
        }
        .error-node {
          border: 2px solid #ff0000 !important;
        }
        .single-item-active {
          border: 2px solid #2a61ff;
          &:hover {
            border: 2px solid #2a61ff;
          }
        }
        .single-item-input {
          height: 68px;
        }
        .single-item-selector {
          height: 68px;
        }
        .single-item-companySelector {
          height: 68px;
        }
        .single-item-textarea {
          height: 107px;
        }
        .ghost-half {
          width: calc(50% - 15px);
          background: rgba(42, 97, 255, 0.1);
          border: 1px dotted #2a61ff;
          .component-image {
            opacity: 0;
          }
          .component-label {
            opacity: 0;
          }
        }
      }
    }
    .props-part {
      width: 280px;
      height: 100%;
      border-left: 1px solid #e3e8ee;
      background: #ffffff;
    }
  }
}
</style>